module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/alx/projects/hello-world-gatsby/src/locales","languages":["en","ru"],"defaultLanguage":"en","i18nextOptions":{"redirect":true,"debug":false,"lowerCaseLng":true,"transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":["br"],"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":71083150,"webvisor":true,"afterBody":true,"defer":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
